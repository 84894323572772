<template>
    <div class="col-5">
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row float-right">
                    <v-text-field
                        :label="$t('message.filterResults')"
                        :value="searchTerm"
                        autocomplete="off"
                        class="mt-2 mb-0 force-text-left"
                        prepend-inner-icon="mdi-filter-outline"
                        @change="searchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="searchTerm = null"><v-icon>refresh</v-icon></v-btn>
                    <ExportTable
                        :has-add-function="true"
                        :cols-to-delete="colsToDelete"
                        class="mt-0 mr-0"
                        file-name="certifications"
                        table-id="certificationsTable"
                        style="padding-top: 2px !important;"
                        @add-action="openCreateCertificationDialog()"
                    ></ExportTable>
                    <HelpPopUpV2 help-page-component="CertificationsListing" :no-padding="true"></HelpPopUpV2>
                </v-col>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.certifications"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [20,50,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :height="tableHeight"
            :items="allCertifications"
            :search="searchTerm"
            :options.sync="tableOptions"
            calculate-widths
            class="mt-0 appic-table-light specification-table"
            dense
            fixed-header
            id="certificationsTable"
            item-key="Certification.id"
        >
            <template v-slot:item.Certification.title="{ item }">
                <span class="font-weight-bold">{{ item.Certification.title }}</span>
            </template>
            <template v-slot:item.Certification.type="{ item }">
                <span v-if="item.Certification.type != null">{{ item.Certification.type.toUpperCase() }}</span>
            </template>
            <template v-slot:item.Certification.id="{ item }">
                <div class="text-center" v-if="item.Certification.id != 0">
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.moreActions') }}</span>
                            </v-tooltip>
                        </template>
                        <v-list dense>
                            <v-list-item class="font-sm" @click="openEditCertificationDialog(item.Certification.id, item.Certification.title)">
                                <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateCertification') }}
                            </v-list-item>
                            <v-list-item class="font-sm" @click="deleteCertification(item.Certification.id, item.Certification.title)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteCertification') }}</v-list-item>
                        </v-list>
                    </v-menu>
                </div>
                <div class="text-center" v-else>
                    <v-icon small color="red darken-1">lock</v-icon>
                </div>
            </template>
        </v-data-table>
        <AddCertification
            :certification-id="selectedCertificationId"
            :certification-name="selectedCertificationName"
            :dialog.sync="editDialog"
            :update-mode="updateMode"
            @dialog-closed="editDialogClosed"
            @update-done="certificationUpdated"
            @create-done="certificationCreated"
        ></AddCertification>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

const AddCertification = () => import("Components/Appic/AddCertification");
const ExportTable = () => import("Components/Appic/ExportTable");
const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");

export default {
    name: "CertificationsListing",
    components: { AddCertification, ExportTable, HelpPopUpV2 },
    props: ['addDialog'],
    data() {
        return {
            colsToDelete: {
                start_col: 0,
                ncols: 1
            },
            dialogs: {
                error: false,
                error_message: ""
            },
            editDialog: null,
            hideDefaultFooter: true,
            loading: {
                fetch: false,
                filterResults: false,
                certifications: false
            },
            searchField: null,
            searchTerm: null,
            selectedCertificationId: null,
            selectedCertificationName: null,
            tableHeight: '500',
            tableOptions: {
                page: 1
            },
            totalCertifications: 0,
            updateMode: false
        }
    },
    computed: {
        ...mapGetters('certification', {
            allCertifications: 'allCertifications'
        }),
        headers () {
            return [
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'Certification.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.certification'),
                    value: 'Certification.title',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.type'),
                    value: 'Certification.type',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.number'),
                    value: 'Certification.cert_no',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                },
                {
                    id: 5,
                    text: this.$t('message.expiryDate'),
                    value: 'Certification.cert_expiry_date',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
            ]
        }
    },
    methods: {
        ...mapActions('certification', {
            deleteCertificationById: 'deleteCertificationById',
            getAllCertifications: 'getAllCertifications',
            resetState: 'resetState'
        }),
        async deleteCertification(val, title) {
            if(await this.$root.$confirm(this.$t('message.deleteCertification') + ' ' +  title, this.$t('message.confirmations.continueCertificationCancelAction'), {color: 'orange'})){
                this.deleteCertificationById(val).then((status) => {
                    if(status == 'done'){
                        this.$toast.success(this.$t('message.successes.certificationDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.resetState()
                        this.getAllCertifications()
                    } else {
                        this.$toast.error(this.$t('message.errors.certificationNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                })
            }
        },
        editDialogClosed () {
            this.editDialog = false;
            if(this.updateMode == false){
                this.$emit('add-dialog-closed')
            }
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (285);
        },
        openCreateCertificationDialog () {
            this.updateMode = false
            this.editDialog = true
        },
        openEditCertificationDialog (certificationId, certificationName) {
            this.selectedCertificationId = certificationId
            this.selectedCertificationName = certificationName
            this.updateMode = true
            this.editDialog = true
        },
        certificationCreated () {
            this.editDialog = false;
            this.resetState()
            this.$emit('reload')
        },
        certificationUpdated () {
            this.editDialog = false;
            this.resetState()
            this.$emit('reload')
        },
    },
    watch: {
        addDialog(value) {
            if(value) {
                this.openCreateCertificationDialog()
            }
        }
    },
    created(){
        this.resetState()
        if(this.allCertifications.length == 0) this.getAllCertifications()
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.handleResize()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style scoped>

</style>